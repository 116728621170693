/* eslint-disable import/max-dependencies */
import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Div from "../../../packages/Div/src";
import Logo from "../../../packages/Logo/src";
import { Grid, GridItem } from "../../../packages/Grid/src";
import { Heading, P } from "../../../packages/Typography/src";
import Link from "../../../packages/Link/src";
import Flex from "../../../packages/Flex/src";
import Icon from "../../../packages/Icon/src";
import Button from "../../../packages/Button/src";

var SiteFooterPattern = function SiteFooterPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Div, {
    bg: "blurble.900",
    py: "5rem"
  }, React.createElement(Container, null, React.createElement(Grid, null, React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 3"]
  }, React.createElement(Logo, null), React.createElement(P, {
    color: "white",
    mt: "1.5rem",
    mb: "0"
  }, "Folkungagatan 122", " ", React.createElement("br", null), "116 30 Stockholm", " ", React.createElement("br", null), "Sweden")), React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 3"]
  }, React.createElement(Heading, {
    as: "h2",
    fontSize: "xs",
    textTransform: "uppercase",
    color: "white",
    muted: true,
    my: "1rem"
  }, "Products"), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Data stories")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Topics")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Charts")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Data")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Blog")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Latest updates")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Collaborations"))), React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 3"]
  }, React.createElement(Heading, {
    as: "h2",
    fontSize: "xs",
    textTransform: "uppercase",
    color: "white",
    muted: true,
    my: "1rem"
  }, "About"), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "About")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Team")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Volunteer")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Partners")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Media room")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "Licensing")), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(P, {
    color: "white",
    my: "0.5rem"
  }, "FAQ"))), React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 3"]
  }, React.createElement(Heading, {
    as: "h2",
    fontSize: "xs",
    textTransform: "uppercase",
    color: "white",
    muted: true,
    my: "1rem"
  }, "Contact"), React.createElement(P, {
    color: "white",
    mt: "0.75rem",
    mb: "2rem"
  }, "Get in touch with us at", React.createElement("br", null), React.createElement(Link, {
    href: "mailto:hello@datastory.org",
    variant: "stealth"
  }, "hello@datastory.org")), React.createElement(P, {
    color: "white",
    mb: "0.75rem"
  }, "Connect on social media"), React.createElement(Flex, {
    mx: "-0.5rem"
  }, React.createElement(Button, {
    px: "0",
    width: "md",
    size: "md",
    borderRadius: "circle",
    mx: "0.5rem"
  }, React.createElement(Icon, {
    name: "facebook",
    color: "white"
  })), React.createElement(Button, {
    px: "0",
    width: "md",
    size: "md",
    borderRadius: "circle",
    mx: "0.5rem"
  }, React.createElement(Icon, {
    name: "twitter",
    color: "white"
  })), React.createElement(Button, {
    px: "0",
    width: "md",
    size: "md",
    borderRadius: "circle",
    mx: "0.5rem"
  }, React.createElement(Icon, {
    name: "github",
    color: "white"
  })))))), "site footer"));
};

export default SiteFooterPattern;